import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import CustomCard from "../../../../src/components/CustomCard/CustomCard.js";
import { OvalDecor, RectangleDecor } from "../../../../src/components/decors.js";
import "../../../../src/styles/custom-card-group.scss";
import { withPrefix } from 'gatsby-link';
import * as React from 'react';
export default {
  HomepageTemplate,
  CustomCard,
  OvalDecor,
  RectangleDecor,
  withPrefix,
  React
};