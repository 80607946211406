// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "SwitcherShadow-module--cds--assistive-text--1bf53";
export var cdsHideFeedback = "SwitcherShadow-module--cds--hide-feedback--19a5a";
export var cdsLayoutConstraintDensity__defaultCondensed = "SwitcherShadow-module--cds--layout-constraint--density__default-condensed--a52a0";
export var cdsLayoutConstraintDensity__defaultNormal = "SwitcherShadow-module--cds--layout-constraint--density__default-normal--efc16";
export var cdsLayoutConstraintDensity__maxCondensed = "SwitcherShadow-module--cds--layout-constraint--density__max-condensed--f7f7b";
export var cdsLayoutConstraintDensity__maxNormal = "SwitcherShadow-module--cds--layout-constraint--density__max-normal--bf49f";
export var cdsLayoutConstraintDensity__minCondensed = "SwitcherShadow-module--cds--layout-constraint--density__min-condensed--cdac0";
export var cdsLayoutConstraintDensity__minNormal = "SwitcherShadow-module--cds--layout-constraint--density__min-normal--9ff97";
export var cdsLayoutConstraintSize__default2xl = "SwitcherShadow-module--cds--layout-constraint--size__default-2xl--3d0ee";
export var cdsLayoutConstraintSize__defaultLg = "SwitcherShadow-module--cds--layout-constraint--size__default-lg--cba25";
export var cdsLayoutConstraintSize__defaultMd = "SwitcherShadow-module--cds--layout-constraint--size__default-md--b41e2";
export var cdsLayoutConstraintSize__defaultSm = "SwitcherShadow-module--cds--layout-constraint--size__default-sm--c10ec";
export var cdsLayoutConstraintSize__defaultXl = "SwitcherShadow-module--cds--layout-constraint--size__default-xl--b3503";
export var cdsLayoutConstraintSize__defaultXs = "SwitcherShadow-module--cds--layout-constraint--size__default-xs--9639d";
export var cdsLayoutConstraintSize__max2xl = "SwitcherShadow-module--cds--layout-constraint--size__max-2xl--fe164";
export var cdsLayoutConstraintSize__maxLg = "SwitcherShadow-module--cds--layout-constraint--size__max-lg--c40bf";
export var cdsLayoutConstraintSize__maxMd = "SwitcherShadow-module--cds--layout-constraint--size__max-md--42ec6";
export var cdsLayoutConstraintSize__maxSm = "SwitcherShadow-module--cds--layout-constraint--size__max-sm--3c4f0";
export var cdsLayoutConstraintSize__maxXl = "SwitcherShadow-module--cds--layout-constraint--size__max-xl--b44bb";
export var cdsLayoutConstraintSize__maxXs = "SwitcherShadow-module--cds--layout-constraint--size__max-xs--1ccd4";
export var cdsLayoutConstraintSize__min2xl = "SwitcherShadow-module--cds--layout-constraint--size__min-2xl--78312";
export var cdsLayoutConstraintSize__minLg = "SwitcherShadow-module--cds--layout-constraint--size__min-lg--221c3";
export var cdsLayoutConstraintSize__minMd = "SwitcherShadow-module--cds--layout-constraint--size__min-md--0ecb7";
export var cdsLayoutConstraintSize__minSm = "SwitcherShadow-module--cds--layout-constraint--size__min-sm--36de0";
export var cdsLayoutConstraintSize__minXl = "SwitcherShadow-module--cds--layout-constraint--size__min-xl--9dfcd";
export var cdsLayoutConstraintSize__minXs = "SwitcherShadow-module--cds--layout-constraint--size__min-xs--28581";
export var cdsLayoutDensityCondensed = "SwitcherShadow-module--cds--layout--density-condensed--50fec";
export var cdsLayoutDensityNormal = "SwitcherShadow-module--cds--layout--density-normal--4a692";
export var cdsLayoutSize2xl = "SwitcherShadow-module--cds--layout--size-2xl--dd802";
export var cdsLayoutSizeLg = "SwitcherShadow-module--cds--layout--size-lg--df8be";
export var cdsLayoutSizeMd = "SwitcherShadow-module--cds--layout--size-md--b10d8";
export var cdsLayoutSizeSm = "SwitcherShadow-module--cds--layout--size-sm--9578b";
export var cdsLayoutSizeXl = "SwitcherShadow-module--cds--layout--size-xl--97f84";
export var cdsLayoutSizeXs = "SwitcherShadow-module--cds--layout--size-xs--48eee";
export var cdsShowFeedback = "SwitcherShadow-module--cds--show-feedback--cf2c5";
export var cdsSkeleton = "SwitcherShadow-module--cds--skeleton--0884d";
export var cdsVisuallyHidden = "SwitcherShadow-module--cds--visually-hidden--a1d33";
export var nav = "SwitcherShadow-module--nav--d0361";
export var open = "SwitcherShadow-module--open--bbd37";