// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "CustomCard-module--cds--assistive-text--91434";
export var cdsHideFeedback = "CustomCard-module--cds--hide-feedback--028f2";
export var cdsLayoutConstraintDensity__defaultCondensed = "CustomCard-module--cds--layout-constraint--density__default-condensed--d7a93";
export var cdsLayoutConstraintDensity__defaultNormal = "CustomCard-module--cds--layout-constraint--density__default-normal--224ef";
export var cdsLayoutConstraintDensity__maxCondensed = "CustomCard-module--cds--layout-constraint--density__max-condensed--f3c18";
export var cdsLayoutConstraintDensity__maxNormal = "CustomCard-module--cds--layout-constraint--density__max-normal--f940d";
export var cdsLayoutConstraintDensity__minCondensed = "CustomCard-module--cds--layout-constraint--density__min-condensed--3b3e2";
export var cdsLayoutConstraintDensity__minNormal = "CustomCard-module--cds--layout-constraint--density__min-normal--01b5d";
export var cdsLayoutConstraintSize__default2xl = "CustomCard-module--cds--layout-constraint--size__default-2xl--1b928";
export var cdsLayoutConstraintSize__defaultLg = "CustomCard-module--cds--layout-constraint--size__default-lg--4d801";
export var cdsLayoutConstraintSize__defaultMd = "CustomCard-module--cds--layout-constraint--size__default-md--e7c61";
export var cdsLayoutConstraintSize__defaultSm = "CustomCard-module--cds--layout-constraint--size__default-sm--55e56";
export var cdsLayoutConstraintSize__defaultXl = "CustomCard-module--cds--layout-constraint--size__default-xl--a85b3";
export var cdsLayoutConstraintSize__defaultXs = "CustomCard-module--cds--layout-constraint--size__default-xs--7f06b";
export var cdsLayoutConstraintSize__max2xl = "CustomCard-module--cds--layout-constraint--size__max-2xl--6547d";
export var cdsLayoutConstraintSize__maxLg = "CustomCard-module--cds--layout-constraint--size__max-lg--8cd62";
export var cdsLayoutConstraintSize__maxMd = "CustomCard-module--cds--layout-constraint--size__max-md--030ce";
export var cdsLayoutConstraintSize__maxSm = "CustomCard-module--cds--layout-constraint--size__max-sm--354a0";
export var cdsLayoutConstraintSize__maxXl = "CustomCard-module--cds--layout-constraint--size__max-xl--86d52";
export var cdsLayoutConstraintSize__maxXs = "CustomCard-module--cds--layout-constraint--size__max-xs--99f3c";
export var cdsLayoutConstraintSize__min2xl = "CustomCard-module--cds--layout-constraint--size__min-2xl--86b6b";
export var cdsLayoutConstraintSize__minLg = "CustomCard-module--cds--layout-constraint--size__min-lg--75486";
export var cdsLayoutConstraintSize__minMd = "CustomCard-module--cds--layout-constraint--size__min-md--0d39f";
export var cdsLayoutConstraintSize__minSm = "CustomCard-module--cds--layout-constraint--size__min-sm--13936";
export var cdsLayoutConstraintSize__minXl = "CustomCard-module--cds--layout-constraint--size__min-xl--f6ad7";
export var cdsLayoutConstraintSize__minXs = "CustomCard-module--cds--layout-constraint--size__min-xs--6a200";
export var cdsLayoutDensityCondensed = "CustomCard-module--cds--layout--density-condensed--60626";
export var cdsLayoutDensityNormal = "CustomCard-module--cds--layout--density-normal--a1e10";
export var cdsLayoutSize2xl = "CustomCard-module--cds--layout--size-2xl--82278";
export var cdsLayoutSizeLg = "CustomCard-module--cds--layout--size-lg--e35de";
export var cdsLayoutSizeMd = "CustomCard-module--cds--layout--size-md--2ef0b";
export var cdsLayoutSizeSm = "CustomCard-module--cds--layout--size-sm--fda1b";
export var cdsLayoutSizeXl = "CustomCard-module--cds--layout--size-xl--1ceb6";
export var cdsLayoutSizeXs = "CustomCard-module--cds--layout--size-xs--2321d";
export var cdsShowFeedback = "CustomCard-module--cds--show-feedback--aa3cb";
export var cdsSkeleton = "CustomCard-module--cds--skeleton--24975";
export var cdsVisuallyHidden = "CustomCard-module--cds--visually-hidden--9de7b";
export var customCard = "CustomCard-module--customCard--2ae9c";
export var customCardContent = "CustomCard-module--customCardContent--812b9";
export var customCardContentDecorator = "CustomCard-module--customCardContentDecorator--60b6f";
export var customCardDark = "CustomCard-module--customCardDark--ffd29";
export var customCardIcon = "CustomCard-module--customCardIcon--2baa6";
export var customCardInner = "CustomCard-module--customCardInner--f7a83";
export var customCardTitle = "CustomCard-module--customCardTitle--08674";