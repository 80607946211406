// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNavShadow-module--cds--assistive-text--2c707";
export var cdsHideFeedback = "LeftNavShadow-module--cds--hide-feedback--15756";
export var cdsLayoutConstraintDensity__defaultCondensed = "LeftNavShadow-module--cds--layout-constraint--density__default-condensed--1bc6f";
export var cdsLayoutConstraintDensity__defaultNormal = "LeftNavShadow-module--cds--layout-constraint--density__default-normal--f25ec";
export var cdsLayoutConstraintDensity__maxCondensed = "LeftNavShadow-module--cds--layout-constraint--density__max-condensed--521ef";
export var cdsLayoutConstraintDensity__maxNormal = "LeftNavShadow-module--cds--layout-constraint--density__max-normal--b370b";
export var cdsLayoutConstraintDensity__minCondensed = "LeftNavShadow-module--cds--layout-constraint--density__min-condensed--9b17c";
export var cdsLayoutConstraintDensity__minNormal = "LeftNavShadow-module--cds--layout-constraint--density__min-normal--7847d";
export var cdsLayoutConstraintSize__default2xl = "LeftNavShadow-module--cds--layout-constraint--size__default-2xl--1d7b4";
export var cdsLayoutConstraintSize__defaultLg = "LeftNavShadow-module--cds--layout-constraint--size__default-lg--e9fe2";
export var cdsLayoutConstraintSize__defaultMd = "LeftNavShadow-module--cds--layout-constraint--size__default-md--666ce";
export var cdsLayoutConstraintSize__defaultSm = "LeftNavShadow-module--cds--layout-constraint--size__default-sm--cf226";
export var cdsLayoutConstraintSize__defaultXl = "LeftNavShadow-module--cds--layout-constraint--size__default-xl--86b93";
export var cdsLayoutConstraintSize__defaultXs = "LeftNavShadow-module--cds--layout-constraint--size__default-xs--5c1fd";
export var cdsLayoutConstraintSize__max2xl = "LeftNavShadow-module--cds--layout-constraint--size__max-2xl--78d88";
export var cdsLayoutConstraintSize__maxLg = "LeftNavShadow-module--cds--layout-constraint--size__max-lg--5eb9e";
export var cdsLayoutConstraintSize__maxMd = "LeftNavShadow-module--cds--layout-constraint--size__max-md--b0a98";
export var cdsLayoutConstraintSize__maxSm = "LeftNavShadow-module--cds--layout-constraint--size__max-sm--68374";
export var cdsLayoutConstraintSize__maxXl = "LeftNavShadow-module--cds--layout-constraint--size__max-xl--6dfa3";
export var cdsLayoutConstraintSize__maxXs = "LeftNavShadow-module--cds--layout-constraint--size__max-xs--d05ea";
export var cdsLayoutConstraintSize__min2xl = "LeftNavShadow-module--cds--layout-constraint--size__min-2xl--a0cf3";
export var cdsLayoutConstraintSize__minLg = "LeftNavShadow-module--cds--layout-constraint--size__min-lg--700c8";
export var cdsLayoutConstraintSize__minMd = "LeftNavShadow-module--cds--layout-constraint--size__min-md--d08d0";
export var cdsLayoutConstraintSize__minSm = "LeftNavShadow-module--cds--layout-constraint--size__min-sm--45581";
export var cdsLayoutConstraintSize__minXl = "LeftNavShadow-module--cds--layout-constraint--size__min-xl--2d500";
export var cdsLayoutConstraintSize__minXs = "LeftNavShadow-module--cds--layout-constraint--size__min-xs--0da79";
export var cdsLayoutDensityCondensed = "LeftNavShadow-module--cds--layout--density-condensed--b1c8f";
export var cdsLayoutDensityNormal = "LeftNavShadow-module--cds--layout--density-normal--38f33";
export var cdsLayoutSize2xl = "LeftNavShadow-module--cds--layout--size-2xl--6004f";
export var cdsLayoutSizeLg = "LeftNavShadow-module--cds--layout--size-lg--989d7";
export var cdsLayoutSizeMd = "LeftNavShadow-module--cds--layout--size-md--eb287";
export var cdsLayoutSizeSm = "LeftNavShadow-module--cds--layout--size-sm--61626";
export var cdsLayoutSizeXl = "LeftNavShadow-module--cds--layout--size-xl--5f444";
export var cdsLayoutSizeXs = "LeftNavShadow-module--cds--layout--size-xs--19de0";
export var cdsShowFeedback = "LeftNavShadow-module--cds--show-feedback--16332";
export var cdsSkeleton = "LeftNavShadow-module--cds--skeleton--e90d2";
export var cdsVisuallyHidden = "LeftNavShadow-module--cds--visually-hidden--261b4";
export var leftNavShadowWrapper = "LeftNavShadow-module--leftNavShadowWrapper--29156";
export var sideNavShadow = "LeftNavShadow-module--sideNavShadow--7e180";
export var sideNavWhiteShadow = "LeftNavShadow-module--sideNavWhiteShadow--27f92";
export var title = "LeftNavShadow-module--title--c9c2c";