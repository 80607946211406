// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "ExampleMedia-module--cds--assistive-text--daee8";
export var cdsHideFeedback = "ExampleMedia-module--cds--hide-feedback--498ce";
export var cdsLayoutConstraintDensity__defaultCondensed = "ExampleMedia-module--cds--layout-constraint--density__default-condensed--5c577";
export var cdsLayoutConstraintDensity__defaultNormal = "ExampleMedia-module--cds--layout-constraint--density__default-normal--f61a8";
export var cdsLayoutConstraintDensity__maxCondensed = "ExampleMedia-module--cds--layout-constraint--density__max-condensed--164dd";
export var cdsLayoutConstraintDensity__maxNormal = "ExampleMedia-module--cds--layout-constraint--density__max-normal--ec5c8";
export var cdsLayoutConstraintDensity__minCondensed = "ExampleMedia-module--cds--layout-constraint--density__min-condensed--b4f17";
export var cdsLayoutConstraintDensity__minNormal = "ExampleMedia-module--cds--layout-constraint--density__min-normal--8053b";
export var cdsLayoutConstraintSize__default2xl = "ExampleMedia-module--cds--layout-constraint--size__default-2xl--8db3e";
export var cdsLayoutConstraintSize__defaultLg = "ExampleMedia-module--cds--layout-constraint--size__default-lg--f2fc8";
export var cdsLayoutConstraintSize__defaultMd = "ExampleMedia-module--cds--layout-constraint--size__default-md--3c18c";
export var cdsLayoutConstraintSize__defaultSm = "ExampleMedia-module--cds--layout-constraint--size__default-sm--6f24f";
export var cdsLayoutConstraintSize__defaultXl = "ExampleMedia-module--cds--layout-constraint--size__default-xl--7728e";
export var cdsLayoutConstraintSize__defaultXs = "ExampleMedia-module--cds--layout-constraint--size__default-xs--2655c";
export var cdsLayoutConstraintSize__max2xl = "ExampleMedia-module--cds--layout-constraint--size__max-2xl--22efb";
export var cdsLayoutConstraintSize__maxLg = "ExampleMedia-module--cds--layout-constraint--size__max-lg--02347";
export var cdsLayoutConstraintSize__maxMd = "ExampleMedia-module--cds--layout-constraint--size__max-md--63949";
export var cdsLayoutConstraintSize__maxSm = "ExampleMedia-module--cds--layout-constraint--size__max-sm--d59b0";
export var cdsLayoutConstraintSize__maxXl = "ExampleMedia-module--cds--layout-constraint--size__max-xl--9227a";
export var cdsLayoutConstraintSize__maxXs = "ExampleMedia-module--cds--layout-constraint--size__max-xs--951a6";
export var cdsLayoutConstraintSize__min2xl = "ExampleMedia-module--cds--layout-constraint--size__min-2xl--129af";
export var cdsLayoutConstraintSize__minLg = "ExampleMedia-module--cds--layout-constraint--size__min-lg--87f0f";
export var cdsLayoutConstraintSize__minMd = "ExampleMedia-module--cds--layout-constraint--size__min-md--f1470";
export var cdsLayoutConstraintSize__minSm = "ExampleMedia-module--cds--layout-constraint--size__min-sm--1ffe7";
export var cdsLayoutConstraintSize__minXl = "ExampleMedia-module--cds--layout-constraint--size__min-xl--4e119";
export var cdsLayoutConstraintSize__minXs = "ExampleMedia-module--cds--layout-constraint--size__min-xs--6d8cf";
export var cdsLayoutDensityCondensed = "ExampleMedia-module--cds--layout--density-condensed--6836d";
export var cdsLayoutDensityNormal = "ExampleMedia-module--cds--layout--density-normal--bf08e";
export var cdsLayoutSize2xl = "ExampleMedia-module--cds--layout--size-2xl--d53ab";
export var cdsLayoutSizeLg = "ExampleMedia-module--cds--layout--size-lg--e2702";
export var cdsLayoutSizeMd = "ExampleMedia-module--cds--layout--size-md--729c4";
export var cdsLayoutSizeSm = "ExampleMedia-module--cds--layout--size-sm--b5370";
export var cdsLayoutSizeXl = "ExampleMedia-module--cds--layout--size-xl--f2b8f";
export var cdsLayoutSizeXs = "ExampleMedia-module--cds--layout--size-xs--4157e";
export var cdsShowFeedback = "ExampleMedia-module--cds--show-feedback--9877a";
export var cdsSkeleton = "ExampleMedia-module--cds--skeleton--0f203";
export var cdsVisuallyHidden = "ExampleMedia-module--cds--visually-hidden--d9873";
export var content = "ExampleMedia-module--content--76082";
export var image = "ExampleMedia-module--image--deae1";
export var imageWrapper = "ExampleMedia-module--imageWrapper--cda61";
export var tag = "ExampleMedia-module--tag--16fb7";
export var tile = "ExampleMedia-module--tile--e1321";
export var title = "ExampleMedia-module--title--3d208";
export var wrapper = "ExampleMedia-module--wrapper--ad0ff";