// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "HeaderShadow-module--cds--assistive-text--5cb8f";
export var cdsHideFeedback = "HeaderShadow-module--cds--hide-feedback--eb382";
export var cdsLayoutConstraintDensity__defaultCondensed = "HeaderShadow-module--cds--layout-constraint--density__default-condensed--e8ab3";
export var cdsLayoutConstraintDensity__defaultNormal = "HeaderShadow-module--cds--layout-constraint--density__default-normal--5fca1";
export var cdsLayoutConstraintDensity__maxCondensed = "HeaderShadow-module--cds--layout-constraint--density__max-condensed--a3782";
export var cdsLayoutConstraintDensity__maxNormal = "HeaderShadow-module--cds--layout-constraint--density__max-normal--28956";
export var cdsLayoutConstraintDensity__minCondensed = "HeaderShadow-module--cds--layout-constraint--density__min-condensed--58b13";
export var cdsLayoutConstraintDensity__minNormal = "HeaderShadow-module--cds--layout-constraint--density__min-normal--70ece";
export var cdsLayoutConstraintSize__default2xl = "HeaderShadow-module--cds--layout-constraint--size__default-2xl--0aac0";
export var cdsLayoutConstraintSize__defaultLg = "HeaderShadow-module--cds--layout-constraint--size__default-lg--93090";
export var cdsLayoutConstraintSize__defaultMd = "HeaderShadow-module--cds--layout-constraint--size__default-md--9d35b";
export var cdsLayoutConstraintSize__defaultSm = "HeaderShadow-module--cds--layout-constraint--size__default-sm--33987";
export var cdsLayoutConstraintSize__defaultXl = "HeaderShadow-module--cds--layout-constraint--size__default-xl--aba4f";
export var cdsLayoutConstraintSize__defaultXs = "HeaderShadow-module--cds--layout-constraint--size__default-xs--a166f";
export var cdsLayoutConstraintSize__max2xl = "HeaderShadow-module--cds--layout-constraint--size__max-2xl--11d8c";
export var cdsLayoutConstraintSize__maxLg = "HeaderShadow-module--cds--layout-constraint--size__max-lg--3a0e7";
export var cdsLayoutConstraintSize__maxMd = "HeaderShadow-module--cds--layout-constraint--size__max-md--98c59";
export var cdsLayoutConstraintSize__maxSm = "HeaderShadow-module--cds--layout-constraint--size__max-sm--8e664";
export var cdsLayoutConstraintSize__maxXl = "HeaderShadow-module--cds--layout-constraint--size__max-xl--d867a";
export var cdsLayoutConstraintSize__maxXs = "HeaderShadow-module--cds--layout-constraint--size__max-xs--2daaa";
export var cdsLayoutConstraintSize__min2xl = "HeaderShadow-module--cds--layout-constraint--size__min-2xl--3de3e";
export var cdsLayoutConstraintSize__minLg = "HeaderShadow-module--cds--layout-constraint--size__min-lg--d416a";
export var cdsLayoutConstraintSize__minMd = "HeaderShadow-module--cds--layout-constraint--size__min-md--8b58b";
export var cdsLayoutConstraintSize__minSm = "HeaderShadow-module--cds--layout-constraint--size__min-sm--9f064";
export var cdsLayoutConstraintSize__minXl = "HeaderShadow-module--cds--layout-constraint--size__min-xl--55fbf";
export var cdsLayoutConstraintSize__minXs = "HeaderShadow-module--cds--layout-constraint--size__min-xs--d9ad2";
export var cdsLayoutDensityCondensed = "HeaderShadow-module--cds--layout--density-condensed--e6f9a";
export var cdsLayoutDensityNormal = "HeaderShadow-module--cds--layout--density-normal--4a3ab";
export var cdsLayoutSize2xl = "HeaderShadow-module--cds--layout--size-2xl--8e299";
export var cdsLayoutSizeLg = "HeaderShadow-module--cds--layout--size-lg--831df";
export var cdsLayoutSizeMd = "HeaderShadow-module--cds--layout--size-md--1a7c5";
export var cdsLayoutSizeSm = "HeaderShadow-module--cds--layout--size-sm--27e98";
export var cdsLayoutSizeXl = "HeaderShadow-module--cds--layout--size-xl--1c0ea";
export var cdsLayoutSizeXs = "HeaderShadow-module--cds--layout--size-xs--beba6";
export var cdsShowFeedback = "HeaderShadow-module--cds--show-feedback--d4bad";
export var cdsSkeleton = "HeaderShadow-module--cds--skeleton--b0253";
export var cdsVisuallyHidden = "HeaderShadow-module--cds--visually-hidden--9b64b";
export var collapsed = "HeaderShadow-module--collapsed--d5456";
export var headerNameShadow = "HeaderShadow-module--headerNameShadow--f2fa9";
export var headerShadow = "HeaderShadow-module--headerShadow--24777";